import { $, $$ } from '../helpers/query-selector';

function init() {
  const roots = $$('.js-cursor');
  let isVisible = false;
  let ticking = false;

  function updateCursorPosition(e) {
    for (const root of roots) {
      const rect = root.getBoundingClientRect();

      root.style.setProperty('--cursor-x', `${e.clientX - rect.left}px`);
      root.style.setProperty('--cursor-y', `${e.clientY - rect.top}px`);

      root.cursorX = e.clientX - rect.left;
      root.cursorY = e.clientY - rect.top;
    }
  }

  let scrollY = window.scrollY;
  let hasScrolled = false;

  // Get relative scroll distance
  window.addEventListener('scroll', () => {
    if (hasScrolled) return;
    scrollY = window.scrollY;

    hasScrolled = true;
  });

  window.addEventListener('scrollend', () => {
    for (const root of roots) {
      root.style.setProperty('--cursor-x', `${root.cursorX}px`);
      root.style.setProperty(
        '--cursor-y',
        `${root.cursorY + (window.scrollY - scrollY)}px`
      );

      root.cursorY += window.scrollY - scrollY;
    }

    scrollY = window.scrollY;
    hasScrolled = false;
  });

  window.addEventListener('mousemove', e => {
    if (ticking) return;

    window.requestAnimationFrame(() => {
      updateCursorPosition(e);
      ticking = false;

      if (!isVisible) {
        setTimeout(() => {
          for (const root of roots) {
            root.classList.add('visible');
          }
        }, 200);

        isVisible = true;
      }
    });

    ticking = true;
  });
}

export default init;
