import { $, $$ } from '../helpers/query-selector';
import { viewport } from '../helpers/variables';
import animateScrollTo from 'animated-scroll-to';
import { reducedMotion } from '../helpers/variables';

function init() {
  const main = $('.js-main');
  const navToggle = $('.js-nav-toggle');
  const navIcon = $('.js-nav-icon');
  const navIconActiveClass = 'c-nav-icon--active';
  const flyout = $('.js-flyout');
  const flyoutVisibleClass = 'visible';
  const links = $$('.js-nav-list a');

  function animateScroll() {
    for (const link of links) {
      if (link.classList.contains('js-nav-anchor-scroll')) {
        link.addEventListener('click', e => {
          e.preventDefault();

          closeNav();

          const url = new URL(e.target.href);
          if (!url.hash) return;

          const target = $(url.hash);
          if (!target) return;

          const options = {
            maxDuration: reducedMotion.matches ? 0 : 1000,
            speed: 400,
            easing: t => {
              return --t * t * t + 1;
            }
          };

          animateScrollTo(target, options);
        });
      }
    }
  }

  function openNav() {
    navIcon.classList.add(navIconActiveClass);
    navToggle.setAttribute('aria-expanded', true);

    flyout.classList.add(flyoutVisibleClass);
  }

  function closeNav() {
    navIcon.classList.remove(navIconActiveClass);
    navToggle.setAttribute('aria-expanded', false);

    flyout.classList.remove(flyoutVisibleClass);
  }

  navToggle.addEventListener('click', () => {
    if (flyout.classList.contains(flyoutVisibleClass)) {
      closeNav();
    } else {
      openNav();
    }
  });

  document.addEventListener('keydown', e => {
    if (e.key === 'Esc' || e.key === 'Escape') closeNav();
  });

  // Close nav on viewport switch
  viewport.m.addEventListener('change', e => {
    if (flyout.classList.contains(flyoutVisibleClass)) {
      closeNav();
    }
  });

  // Add scroll animation
  animateScroll();

  // Close nav conditions
  main.addEventListener('click', () => {
    closeNav();
  });
}

export default init;
