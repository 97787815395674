import '../scss/app.scss'; // Vite requires css import in app.js

import { $ } from './helpers/query-selector';
import initLazyLoad from './modules/lazyload';
import initVideo from './modules/video';
import initNav from './modules/nav';
import initCursor from './modules/cursor';

async function init() {
  // Immediate
  initLazyLoad();
  initVideo();
  initNav();
  initCursor();

  // Conditionally lazyloaded
  // prettier-ignore
  {
    if ($('.js-footer')) import('./modules/footer.js').then(m => m.default());
    if ($('.js-iframe')) import('./modules/iframe.js').then(m => m.default());
    if ($('.js-quotes')) import('./modules/quotes.js').then(m => m.default());
    if ($('.js-accordion')) import('./modules/accordion.js').then(m => m.default());
  }
}

init();
